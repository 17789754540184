import request from "./request";

/**
 * 标签优惠券
 */

/**
 * 查询
 */
export const list = id => request.get("/tc/", { params: { id } });

/**
 * 添加/编辑
 */
export const add = data => request.post("/tc/new", data);

/**
 * 删除
 */
export const del = id => request.post("/tc/del", { id });
