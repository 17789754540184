<template>
  <Layout>
    <div class="app-taglist">
      <a-page-header title="会员等级管理" @back="() => this.$router.back()" />
      <div class="tool">
        <a-form layout="inline">
          <a-button
            @click="(form.name = ''), (showDialog = true)"
            type="primary"
          >
            新建会员等级
          </a-button>
          <a-button @click="showRule" type="link" icon="setting">
            配置规则
          </a-button>
        </a-form>
      </div>
      <div class="table-wrap">
        <a-table :columns="columns" :data-source="list">
          <template slot="name" slot-scope="name, record">
            <editable-cell
              :text="name"
              @change="updateName(record.id, $event)"
            />
          </template>
          <span slot="action" slot-scope="_, record">
            <a @click="showCouponList(record.id, record.name)">会员权益</a>
          </span>
        </a-table>
      </div>
    </div>
    <!-- 添加等级 -->
    <a-modal v-model="showDialog" title="添加会员等级" @ok="add">
      <a-form-model :model="form" ref="form">
        <a-form-model-item
          required
          label="会员等级名称"
          prop="name"
          :rules="[
            {
              required: true,
              message: '会员等级名称必填'
            }
          ]"
        >
          <a-input v-model="form.name" placeholder="会员等级名称" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <!-- 会员权益 -->
    <a-drawer
      :title="`会员权益 - ${coupon.tagName}`"
      :width="720"
      :visible="coupon.show"
      @close="coupon.show = false"
    >
      <div class="tool">
        <a-form layout="inline">
          <a-button @click="showCoupon" type="primary">
            添加优惠券
          </a-button>
        </a-form>
      </div>
      <a-table
        :columns="columnsForCoupon"
        :data-source="coupon.list"
        :pagination="false"
      >
        <span slot="action" slot-scope="_, record">
          <a style="color:#f5222d" @click="del(record.id)">删除</a>
        </span>
      </a-table>
      <a-alert
        :show-icon="false"
        message="提示：会员优惠券允许多次领取，请在创建优惠券时限制领取次数和使用时效。"
        banner
      />
    </a-drawer>
    <!-- 发券 -->
    <a-modal v-model="send.show" title="发放优惠券" @ok="sendCoupon">
      <a-form-model>
        <a-form-model-item>
          <a-select v-model="send.couponId" placeholder="请选择">
            <a-select-option v-for="c in couponList" :key="c.id" :value="c.id"
              >{{ c.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <!-- 配置 -->
    <a-modal
      title="配置规则"
      @ok="submitRule"
      :destroyOnClose="true"
      :visible="ruleDialogVisible"
      @cancel="ruleDialogVisible = false"
    >
      <a-form-model
        :model="rule"
        ref="rule"
        label-align="left"
        :label-col="{ span: 20 }"
        :wrapper-col="{ span: 4 }"
      >
        <a-form-model-item
          required
          label="小商店显示会员等级"
          prop="user_show_vip"
          :rules="[
            {
              required: true
            }
          ]"
        >
          <a-switch v-model="rule.user_show_vip" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </Layout>
</template>

<script>
import Layout from "@/layout/index.vue";

import EditableCell from "@/components/editable-cell.vue";

import loadCouponMixin from "@/mixins/loadCoupon";
import couponMixin from "@/mixins/coupon";

const TagSvc = require("@/service/tag");
const TagCouponSvc = require("@/service/tag_coupon");
const ClientSvc = require("@/service/client");

const { getCouponTips } = require("@/utils/tool");

export default {
  name: "TagList",

  data() {
    return {
      list: [],

      form: { name: "" },
      showDialog: false,

      // 权益
      coupon: {
        show: false,
        list: [],
        tagid: 0,
        tagName: ""
      },

      // 发放优惠券
      send: {
        show: false,
        couponId: null
      },

      // 配置
      ruleDialogVisible: false,
      rule: {
        user_show_vip: false
      }
    };
  },

  computed: {
    columns() {
      return [
        {
          title: "会员等级名称",
          dataIndex: "name",
          key: "name",
          scopedSlots: { customRender: "name" }
        },
        {
          title: "操作",
          key: "action",
          width: 120,
          scopedSlots: { customRender: "action" }
        }
      ];
    },

    columnsForCoupon() {
      return [
        {
          title: "优惠券名称",
          dataIndex: "name",
          key: "name"
        },
        {
          title: "优惠详情",
          dataIndex: "type",
          key: "type",
          customRender: (_, item) => getCouponTips(item)
        },
        {
          title: "已领取",
          align: "right",
          dataIndex: "takeCnt",
          key: "takeCnt",
          width: 80
        },
        {
          title: "操作",
          key: "action",
          width: 80,
          scopedSlots: { customRender: "action" }
        }
      ];
    }
  },

  mixins: [loadCouponMixin, couponMixin],

  methods: {
    // 显示配置
    async showRule() {
      try {
        let { user_show_vip } = await ClientSvc.getRule();
        user_show_vip = !!user_show_vip;
        this.rule = { user_show_vip };
      } catch (err) {
        console.error(err);
      }
      this.ruleDialogVisible = true;
    },
    submitRule() {
      this.$refs.rule.validate(async valid => {
        if (valid) {
          try {
            await ClientSvc.updateRule(this.rule);
            this.$message.info("已设置规则");

            this.ruleDialogVisible = false;
          } catch (err) {
            console.error(err);
            this.$message.error("设置规则失败，错误：" + err.message);
          }
        }
      });
    },

    // 查看权益
    async showCouponList(tagid, tagName) {
      this.coupon.tagid = tagid;
      this.coupon.tagName = tagName;

      const ok = await this.fetchCoupon();
      if (ok) {
        this.coupon.show = true;
      }
    },
    async fetchCoupon() {
      try {
        this.coupon.list = await TagCouponSvc.list(this.coupon.tagid);
        return true;
      } catch (err) {
        console.error(err);
        this.$message.error("加载权益出错，错误：" + err.message);
        return false;
      }
    },

    // 发送优惠券
    showCoupon() {
      this.send.couponId = null;
      this.send.show = true;
    },
    async sendCoupon() {
      const { couponId } = this.send;
      if (!couponId) {
        return;
      }

      const tagid = this.coupon.tagid;

      try {
        const { type, name, num1, num2 } = this.couponList.filter(
          x => x.id === couponId
        )[0];
        await TagCouponSvc.add({ tagid, couponId, type, name, num1, num2 });

        // 刷新
        await this.fetchCoupon();

        this.send = {
          show: false
        };
        this.$message.info("已添加优惠券");
      } catch (err) {
        console.error(err);
        this.$message.error("添加优惠券失败，错误：" + err.message);
      }
    },
    async del(id) {
      this.$confirm({
        title: "操作提示",
        content: "确认删除优惠券吗？",

        onOk: () => {
          this._delete(id);
        },
        onCancel() {}
      });
    },

    async _delete(id) {
      try {
        await TagCouponSvc.del(id);

        await this.fetchCoupon();
      } catch (err) {
        console.error(err);
        this.$message.error("删除失败，错误：" + err.message);
      }
    },

    /**
     * 用户管理
     */
    async add() {
      this.$refs.form.validate(async valid => {
        if (!valid) {
          return false;
        }

        try {
          const { name } = this.form;
          await TagSvc.edit({ name });

          this.showDialog = false;

          await this.fetch();
        } catch (err) {
          console.error(err);
          this.$message.error("添加等级失败，错误：" + err.message);
        }
      });
    },

    async updateName(id, name) {
      try {
        await TagSvc.edit({ id, name });

        const idx = this.list.findIndex(x => x.id == id);
        this.$set(this.list, idx, {
          ...this.list[idx],
          name
        });
      } catch (err) {
        console.error(err);
        this.$message.error("修改名称失败，错误：" + err.message);
      }
    },

    async fetch() {
      try {
        this.list = await TagSvc.list();
      } catch (err) {
        console.error(err);
        this.$message.error("加载等级出错，错误：" + err.message);
      }
    }
  },

  async mounted() {
    await this.fetch();
  },

  components: {
    Layout,
    EditableCell
  }
};
</script>

<style lang="scss">
.app-taglist {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--header-height));
  background: #f0f2f5;
  padding: 24px 24px 0;

  .tool {
    padding: 24px 24px;
    background: #fff;
  }
  .table-wrap {
    background: #fff;

    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 5px;
      overflow: hidden;
      border: 1px solid #fff;
    }

    .ant-table-pagination.ant-pagination {
      float: none;
      text-align: center;
    }
  }
}

.ant-drawer-body {
  .tool {
    padding-bottom: 24px;
    background: #fff;
  }
}
</style>
