var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('div',{staticClass:"app-taglist"},[_c('a-page-header',{attrs:{"title":"会员等级管理"},on:{"back":() => this.$router.back()}}),_c('div',{staticClass:"tool"},[_c('a-form',{attrs:{"layout":"inline"}},[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){(_vm.form.name = ''), (_vm.showDialog = true)}}},[_vm._v(" 新建会员等级 ")]),_c('a-button',{attrs:{"type":"link","icon":"setting"},on:{"click":_vm.showRule}},[_vm._v(" 配置规则 ")])],1)],1),_c('div',{staticClass:"table-wrap"},[_c('a-table',{attrs:{"columns":_vm.columns,"data-source":_vm.list},scopedSlots:_vm._u([{key:"name",fn:function(name, record){return [_c('editable-cell',{attrs:{"text":name},on:{"change":function($event){return _vm.updateName(record.id, $event)}}})]}},{key:"action",fn:function(_, record){return _c('span',{},[_c('a',{on:{"click":function($event){return _vm.showCouponList(record.id, record.name)}}},[_vm._v("会员权益")])])}}])})],1)],1),_c('a-modal',{attrs:{"title":"添加会员等级"},on:{"ok":_vm.add},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('a-form-model',{ref:"form",attrs:{"model":_vm.form}},[_c('a-form-model-item',{attrs:{"required":"","label":"会员等级名称","prop":"name","rules":[
          {
            required: true,
            message: '会员等级名称必填'
          }
        ]}},[_c('a-input',{attrs:{"placeholder":"会员等级名称"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1)],1)],1),_c('a-drawer',{attrs:{"title":`会员权益 - ${_vm.coupon.tagName}`,"width":720,"visible":_vm.coupon.show},on:{"close":function($event){_vm.coupon.show = false}}},[_c('div',{staticClass:"tool"},[_c('a-form',{attrs:{"layout":"inline"}},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.showCoupon}},[_vm._v(" 添加优惠券 ")])],1)],1),_c('a-table',{attrs:{"columns":_vm.columnsForCoupon,"data-source":_vm.coupon.list,"pagination":false},scopedSlots:_vm._u([{key:"action",fn:function(_, record){return _c('span',{},[_c('a',{staticStyle:{"color":"#f5222d"},on:{"click":function($event){return _vm.del(record.id)}}},[_vm._v("删除")])])}}])}),_c('a-alert',{attrs:{"show-icon":false,"message":"提示：会员优惠券允许多次领取，请在创建优惠券时限制领取次数和使用时效。","banner":""}})],1),_c('a-modal',{attrs:{"title":"发放优惠券"},on:{"ok":_vm.sendCoupon},model:{value:(_vm.send.show),callback:function ($$v) {_vm.$set(_vm.send, "show", $$v)},expression:"send.show"}},[_c('a-form-model',[_c('a-form-model-item',[_c('a-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.send.couponId),callback:function ($$v) {_vm.$set(_vm.send, "couponId", $$v)},expression:"send.couponId"}},_vm._l((_vm.couponList),function(c){return _c('a-select-option',{key:c.id,attrs:{"value":c.id}},[_vm._v(_vm._s(c.name)+" ")])}),1)],1)],1)],1),_c('a-modal',{attrs:{"title":"配置规则","destroyOnClose":true,"visible":_vm.ruleDialogVisible},on:{"ok":_vm.submitRule,"cancel":function($event){_vm.ruleDialogVisible = false}}},[_c('a-form-model',{ref:"rule",attrs:{"model":_vm.rule,"label-align":"left","label-col":{ span: 20 },"wrapper-col":{ span: 4 }}},[_c('a-form-model-item',{attrs:{"required":"","label":"小商店显示会员等级","prop":"user_show_vip","rules":[
          {
            required: true
          }
        ]}},[_c('a-switch',{model:{value:(_vm.rule.user_show_vip),callback:function ($$v) {_vm.$set(_vm.rule, "user_show_vip", $$v)},expression:"rule.user_show_vip"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }